<template>
  <b-sidebar
    id="add-new-metodo-pago-sidebar"
    :visible="isAddNewMetodoPagoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-metodo-pago-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar Nueva Carerra
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            name="Nombre Metodo pago"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="nombre"
            >
              <b-form-input
                id="nombre-metodo-pago"
                v-model="metodoPagoData.nombre"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Banco Bisa"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Respuesta -->
          <validation-provider
            #default="validationContext"
            name="Detalle"
            rules="required"
          >
            <b-form-group
              label="detalle"
              label-for="detalle-metodopago"
            >
              <b-form-textarea
                id="detalle-metodopago"
                v-model="metodoPagoData.detalle"
                :state="getValidationState(validationContext)"
                placeholder="Detalles..."
                rows="2"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estado de metodo pago (activo, inactivo) -->
          <validation-provider
            #default="validationContext"
            name="Estado"
            rules="required"
          >
            <b-form-group
              label="Estado"
              label-for="estado"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="metodoPagoData.estado"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="estado"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import store from '@/store'
import { useMutation } from '@vue/apollo-composable'
import ADD_METODO_PAGO from '../../../../graphql/cursos/metodo-pago/AddMetodoPago.gql'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMetodoPagoSidebarActive',
    event: 'update:is-add-new-metodo-pago-sidebar-active',
  },
  props: {
    isAddNewMetodoPagoSidebarActive: {
      type: Boolean,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankMetodoPagoData = {
      nombre: '',
      detalle: '',
      estado: '',
    }

    const metodoPagoData = ref(JSON.parse(JSON.stringify(blankMetodoPagoData)))
    const resetuserData = () => {
      metodoPagoData.value = JSON.parse(JSON.stringify(blankMetodoPagoData))
    }

    const { mutate: sendMetodoPago, onDone, onError } = useMutation(ADD_METODO_PAGO, () => ({
      variables: metodoPagoData.value,
    }))

    onDone(() => {
      emit('refetch-data')
    })

    onError(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    const onSubmit = () => {
      sendMetodoPago()

      emit('refetch-data')
      emit('update:is-add-new-metodo-pago-sidebar-active', false)
      /*
      store.dispatch('app-user/addUser', cursoData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        */
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      metodoPagoData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
